const replaceBrowserHistory = (key: string, value?: string | number | null) => {
  const url = new URL(window.location.href)
  if (!value) {
    url.searchParams.delete(key)
  } else {
    url.searchParams.set(key, String(value))
  }
  window.history.replaceState({}, '', url)

  if (key === 'outputCurrency' || key === 'inputCurrency') {
    const chain = url.searchParams.get('chain') || 'bsc'
    window.localStorage.setItem(`${chain}-${key}`, value ? value.toString() : '')
  }
}

export default replaceBrowserHistory
